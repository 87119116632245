import {computed, ref, watch} from '@vue/composition-api'
import store from '@/store'

// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useTemplatesList() {
  // Use toast
  const toast = useToast()

  const refTemplateListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {key: 'name', sortable: true},
    {key: 'templateDocumentType', sortable: true},
    {key: 'createdAt', sortable: true},
    {key: 'public', sortable: true},
    {key: 'actions'}
  ]

  const perPage = ref(10)
  const totalTemplates = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refTemplateListTable.value ? refTemplateListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalTemplates.value,
    }
  })

  const refetchData = () => {
    refTemplateListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    // console.log("refetch")
    refetchData()
  })

  const fetchTemplates = (ctx, callback) => {
    store
      .dispatch('template/fetchAll', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
      })
      .then(response => {
        totalTemplates.value = response.length
        // callback(JSON.parse(JSON.stringify(response)))
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching template list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const resolveDocumentTypeVariant = type => {
    if (type === '' || type === null) return 'warning'
    return 'info'
  }

  return {
    fetchTemplates,
    tableColumns,
    perPage,
    currentPage,
    totalTemplates,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refTemplateListTable,
    resolveDocumentTypeVariant,

    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
