<template>
  <div class="flex flex-wrap w-full mt-50">
    <b-form-group
        :label="typeDescription"
        :label-for="variableId">
      <validation-provider
          #default="{ errors }"
          :name="variableName"
          :vid="variableName"
          rules="required">

        <v-select v-on:option:selected="inputLocked = false"
                  single
                  v-model="value"
                  :placeholder="$t('choose')"
                  :label="'option'"
                  :options="options">
        </v-select>

        <div v-if="value != null">
          <template v-for="(child,ix) in children">
            <div :key="ix">
              <template v-for="(option,index) in child.options">
                <div :key="index">
                  <b-form-group v-if="index === options.findIndex(op => op.optionId === value.optionId)"
                                class="mt-1"
                                :label="child.description"
                                label-for="childParam">
                    <b-row>
                      <b-col cols="8">
                        <b-form-input :disabled="disabled"
                                      class="h-100" id="childParam"
                                      :value="option.option">
                        </b-form-input>
                      </b-col>
                      <b-col cols="4">
                        <b-button :disabled="inputLocked || disabled"
                                  class="w-100"
                                  @click="processVariableValue(option, child)">
                          {{ $t('variable.add')}}
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </div>
              </template>
            </div>
          </template>
        </div>

        <small v-if="errors.length > 0" class="text-danger">
          {{ $t('required') }}
        </small>
      </validation-provider>
    </b-form-group>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import {ValidationProvider} from "vee-validate";
import {BButton, BCol, BFormGroup, BFormInput, BRow} from "bootstrap-vue";

export default {
  name: "LinkedVariable",
  components: {vSelect, BFormGroup, BButton, BRow, BCol, BFormInput, ValidationProvider,},
  props: {
    variableId: String,
    sectionId: String,
    variableName: String,
    attributeDescription: String,
    typeDescription: String,
    attributeType: String,
    variableValue: {
      type: String
    },
    options: Array,
    children: Array,
    disabled: Boolean
  },
  data() {
    return {
      type: this.attributeType,
      value: this.variableValue,

      inputLocked: false
    }
  },
  methods: {
    processVariableValue(option, child) {
      this.inputLocked = true

      this.$emit('save', {
        varId: child.variableId,
        varName: this.variableName,
        selectedValue: option.option,
        parentVariableValue: this.value.option,
        sectionId: this.sectionId
      })
    },
    documentIdFromUrl() {
      return this.$route.params.id;
    },
  }
}
</script>

<style scoped>

</style>