<template>
  <div>
    <date-type-attribute
        :disabled="disabled"
        :key="getUserValue(variable)"
        v-if="variable.valueType === 'DATE'"
        :variable-id="variable.variableId"
        :document-variable-id="variable.id"
        :section-id="variable.sectionId"
        :type-description="variable.description"
        @save="handleDateInputChange"
        :variable-value="getUserValue(variable)"/>
    <company-type-attribute
        :disabled="disabled"
        :workspace-id="workspaceId"
        v-else-if="variable.type === 'ORG' && variable.valueType === 'SELECTION'"
        :section-id="variable.sectionId"
        :variable-id="variable.variableId"
        @save="handleCompanySelectionChange"
        :type-description="variable.description"
        :placeholder="variable.typeExplanation"
        :variable-value="getUserValue(variable)"/>
    <selectable-variable
        :disabled="disabled"
        v-else-if="variable.type !== 'ORG' && variable.type !== 'ORG_BANK' && variable.valueType === 'SELECTION'"
        :variable-id="variable.variableId"
        :section-id="variable.sectionId"
        @save="handleSelectableInputChange"
        :type-description="variable.description"
        :placeholder="$t('variable.options')"
        :variable-value="getUserValue(variable)"
        :options="variable.options"/>
    <linked-variable
        v-else-if="variable.children !== null && variable.valueType === 'SELECTION'"
        :variable-id="variable.variableId"
        :section-id="variable.sectionId"
        @save="handleLinkedVariableInputChange"
        :type-description="variable.description"
        :placeholder="$t('variable.options')"
        :variable-value="getUserValue(variable)"
        :options="variable.options"
        :children="variable.children"/>
    <input-text-variable
        :disabled="disabled"
        v-else
        @save="handleTextInputChange"
        :placeholder="variable.typeExplanation"
        :variable-id="variable.variableId"
        :type-description="variable.description"
        :section-id="variable.sectionId"
        :variable-value="getUserValue(variable)">
    </input-text-variable>
  </div>
</template>

<script>
import CompanyTypeAttribute from "@/views/Variables/CompanyTypeAttribute";
import DateTypeAttribute from "@/views/Variables/DateTypeVariable";
import InputTextVariable from "@/views/Variables/InputTextVariable";
import SelectableVariable from "@/views/Variables/SelectableVariable";
import VariableDefinitionService, {InputCardType} from "@/services/VariableDefinitionService";
import LinkedVariable from "@/views/Variables/LinkedVariable";

export default {
  name: "DynamicVariableElement",
  components: {
    SelectableVariable,
    CompanyTypeAttribute,
    DateTypeAttribute,
    InputTextVariable,
    LinkedVariable
  },
  props: {
    variable: Object,
    workspaceId: String,
    disabled: Boolean
  },
  computed: {},
  methods: {
    isCompanySelectBoxVariable(type) {
      return VariableDefinitionService.getAttributeInputType(type) === InputCardType.COMPANY_CARD;
    },
    isDateVariable(type) {
      return VariableDefinitionService.getAttributeInputType(type) === InputCardType.DATE_CARD;
    },
    isInputVariable(type) {
      return (VariableDefinitionService.getAttributeInputType(type) === InputCardType.FREE_TEXT_CARD)
          || (VariableDefinitionService.getAttributeInputType(type) === InputCardType.TEXT_AREA_CARD)
    },
    isSelectableVariable(type) {
      return (VariableDefinitionService.getAttributeInputType(type) === InputCardType.SELECTION)
    },
    handleDateInputChange(event) {
      this.setDescription(event)
      this.setInputType(event)
      this.$emit('onDateInputChange', event)
    },
    handleCompanySelectionChange(event) {
      this.setDescription(event)
      this.setInputType(event)
      this.$emit('onCompanySelectionChange', event)
    },
    handleSelectableInputChange(event) {
      this.setDescription(event)
      this.setInputType(event)
      this.$emit('onSelectionInputChange', event)
    },
    handleLinkedVariableInputChange(event) {
      this.setDescription(event)
      this.setInputType(event)
      this.$emit('onLinkedVariableInputChange', event)
    },
    handleTextInputChange(event) {
      this.setDescription(event)
      this.setInputType(event)
      this.$emit('onTextInputChange', event)
    },
    setDescription(event) {
      event['overridingDescription'] = this.variable.overridingDescription === null ? this.variable.description : this.variable.overridingDescription
    },
    setInputType(event) {
      event['inputType'] = this.variable.presentationMethod
    },
    getUserValue(variable) {
      if (variable.userProcessedValue) {
        return variable.userProcessedValue.userValue
      }
      return null
    },
  }
}
</script>

<style scoped>

</style>