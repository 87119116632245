<template>
  <div class="flex flex-wrap w-full">
    <b-form-group
        :label="typeDescription"
        :label-for="variableId">
      <validation-provider
          #default="{ errors }"
          :name="variableName"
          :vid="variableName"
          rules="required">
        <b-form-input
            :disabled="disabled"
            :id="variableId"
            :placeholder="placeholder"
            v-on:keyup.enter="$event.target.blur()"
            @blur="save"
            v-model="inputValue"
            class="w-full">
        </b-form-input>
        <small v-if="errors.length > 0" class="text-danger">
          {{ $t('required') }}
        </small>
      </validation-provider>
    </b-form-group>
  </div>
</template>

<script>
import {IconUtils} from "@/services/Utils";
import {BFormGroup, BFormInput} from 'bootstrap-vue'
import {ValidationProvider} from 'vee-validate'

export default {
  components: {
    BFormInput,
    BFormGroup,
    ValidationProvider,
  },
  data() {
    return {
      inputValue: null
    }
  },
  props: {
    danger: Boolean,
    variableName: String,
    placeholder: String,
    variableId: String,
    sectionId: String,
    typeDescription: String,
    attributeType: String,
    isSuggestionEnabled: {
      type: Boolean,
      default: true
    },
    variableValue: String,
    disabled: Boolean
  },
  watch: {
    inputValue: function (val) {
      this.inputValue = val
    },
    variableValue: function (val) {
      this.inputValue = val
    }
  },
  mounted() {
    this.inputValue = this.variableValue
  },
  name: "InputTextVariable",
  methods: {
    save() {
      this.$emit('save', {varId: this.variableId, inputValue: this.inputValue, sectionId: this.sectionId})
    },
    icon(type) {
      return IconUtils.getIcon(type);
    },
  }
}
</script>

<style scoped>

.attribute-row.danger {
  box-shadow: 0 0 1px 1px #EA5455, 0 0 1px 1px #EA5455;
}
</style>
