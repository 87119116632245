<template>
  <b-modal no-close-on-backdrop
           scrollable
           lazy
           ref="populate-variables-modal"
           hide-footer
           @hidden="resetInputs"
           title="Değişkenleri doldurun">
    <validation-observer
        ref="supplierForm"
        #default="{invalid}">
      <b-form @submit.prevent>
        <b-row align-h="start"
               v-for="(variable, ix) in variables"
               :key="ix">
          <b-col cols="12">
            <dynamic-variable-element
                :workspace-id="selectedWorkspace.organizationId"
                :variable="variable"
                @onTextInputChange="handleTextInputChange"
                @onDateInputChange="handleDateInputChange"
                @onCompanySelectionChange="handleCompanySelectionChange"
                @onSelectionInputChange="handleSelectableInputChange"/>
          </b-col>
        </b-row>
        <!-- submit and reset -->
        <b-col cols="12">
          <br/>
          <b-row align-h="end">
            <b-button
                @click="save"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                :disabled="invalid"
                variant="primary"
            >
              {{ $t('save') }}
            </b-button>
          </b-row>
        </b-col>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {BButton, BCol, BForm, BModal, BRow} from 'bootstrap-vue'
import Ripple from "vue-ripple-directive";
import {ValidationObserver} from 'vee-validate'
import {mapState} from "vuex";
import {PEventBus} from "@/services/PEventBus";
import DynamicVariableElement from "@/views/Documents/Editor/Variables/DynamicVariableElement";

export default {
  name: "PopulateVariablesModal",
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    ValidationObserver,
    BModal,
    DynamicVariableElement
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapState({
      loading: state => state.template.variablesLoading,
      variables: state => state.template.variableList
    }),
  },
  data() {
    return {
      populatedVariables: [],
      selectedTemplateId: null,
      selectedWorkspace: null,
      documentTitle: null,
    }
  },
  mounted() {
    PEventBus.$on('show-populate-variables-modal', this.handleShowModal)
  },
  destroyed() {
    PEventBus.$off('show-populate-variables-modal', this.handleShowModal)
  },
  methods: {
    save() {
      this.$store.dispatch('document/createFromTemplate', {
        templateId: this.selectedTemplateId,
        workspaceId: this.selectedWorkspace.organizationId,
        documentTitle: this.documentTitle,
        values: this.populatedVariables
      }).then((uid) => {
        this.submitting = false
        this.$router.replace(`/documents/${uid}/editor`)
        this.selectedTemplate = null
      }).catch((err) => this.submitting = false)
    },
    resetInputs() {

    },
    loadTemplateVariables(templateId) {
      this.$store.dispatch('template/fetchVariables', templateId)
    },
    handleShowModal(event) {
      this.selectedTemplateId = event.templateId
      this.selectedWorkspace = event.workspace
      this.loadTemplateVariables(event.templateId)
      this.documentTitle = event.documentTitle
      this.$refs['populate-variables-modal'].show()
    },
    handleTextInputChange(event) {
      this.handleInputChange(event)
    },
    handleDateInputChange(event) {
      this.handleInputChange(event)
    },
    handleSelectableInputChange(event) {
      let populatedVariable = {
        variableId: event.varId,
        value: {userValue: event.selectedValue.option, mappedValue: event.selectedValue.option}
      }
      this.populatedVariables.push(populatedVariable)
    },
    handleCompanySelectionChange(event) {
      let populatedVariable = {
        variableId: event.varId,
        value: {userValue: event.selectedValues, mappedValue: event.selectedValues}
      }
      this.populatedVariables.push(populatedVariable)
    },
    handleInputChange(event) {
      let populatedVariable = {
        variableId: event.varId,
        value: {userValue: event.inputValue, mappedValue: event.inputValue}
      }
      this.populatedVariables.push(populatedVariable)
    }
  }
}
</script>

<style scoped>

</style>