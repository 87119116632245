<template>
  <div class="w-100">

    <b-form-group
        :label="typeDescription"
        :label-for="variableId">
      <validation-provider
          #default="{ errors }"
          :name="variableName"
          :vid="variableName"
          rules="required">
        <b-row>
          <b-col :disabled="disabled">
            <flat-pickr v-model="value"
                        :disabled="disabled"
                        :id="variableId"
                        @on-change="save"
                        :config="config"
                        class="form-control"
                        :placeholder="$t('variable.startDate')"/>
          </b-col>
        </b-row>
        <b-row v-if="documentVariableId !== ''"
               class="pt-2">
          <b-col>
            <b-button @click="openAddReminderModal"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary">
              {{ $t('variable.addReminder') }}
            </b-button>
          </b-col>
        </b-row>
        <small v-if="errors.length > 0" class="text-danger">
          {{ $t('required') }}
        </small>
      </validation-provider>
    </b-form-group>

    <b-modal ref="create-reminder-modal"
             hide-footer
             no-close-on-backdrop
             :title="$t('variable.addReminder')">
      <b-col>
        <b-row class="pb-1">
          <b-form-input :placeholder="$t('variable.reminderTitle')"
                        v-model="reminderRequest.title"/>
        </b-row>

        <b-row class="pb-1">
          <b-form-textarea :placeholder="$t('variable.reminderNote')"
                           v-model="reminderRequest.note"/>
        </b-row>

        <b-row class="pb-1">
          <flat-pickr v-model="reminderRequest.startDate"
                      class="form-control"
                      :config="reminderStartDatePickerConfig"
                      :placeholder="$t('variable.reminderStartDate')"/>
        </b-row>

        <b-row class="pb-1">
          <v-select :clearable="false"
                    single
                    :options="executionPlanOptions"
                    v-model="reminderRequest.executionPlan"
                    :placeholder="'displayText'"
                    :label="'displayText'"
                    class="w-100">
          </v-select>
        </b-row>

        <b-row class="pb-1">
          <v-select v-model="selectedGroups"
                    :clearable="false"
                    :options="groups"
                    :label="'name'"
                    multiple
                    :placeholder="$t('variable.reminderReceivers')"
                    class="w-100"
                    v-on:option:selecting="handleSelection($event)"
                    v-on:option:deselecting="handleDeselection($event)">
          </v-select>

          <b-col class="p-2">
            <b-row v-for="(user,ix) in selectedUsers" :key="ix">
              <span>
                {{ user.fullName }}
              </span>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="pb-2">
          <b-button :disabled="!validForAddReminder()"
                    @click="sendTestMail"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="outline-primary">
            {{ $t('variable.sendNotificationMail') }}
          </b-button>
        </b-row>

        <b-row>
          <b-button :disabled="!validForAddReminder()"
                    @click="addReminder"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary">
            {{ $t('buttons.save') }}
          </b-button>
        </b-row>
      </b-col>
    </b-modal>
  </div>
</template>

<script>
import {BButton, BCol, BFormGroup, BFormInput, BFormTextarea, BModal, BRow} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component'
import {ValidationProvider} from 'vee-validate'
import vSelect from "vue-select";
import {mapState} from "vuex";
import Ripple from "vue-ripple-directive";
import i18n from "@/libs/i18n";

const Tr = require("flatpickr/dist/l10n/tr.js").tr;

export default {
  name: "DateTypeVariable",
  components: {
    BFormGroup,
    flatPickr,
    ValidationProvider,
    BRow,
    BCol,
    BModal,
    BFormInput,
    BFormTextarea,
    BButton,
    vSelect
  },
  directives: {
    Ripple,
  },
  props: {
    variableId: String,
    documentVariableId: {
      type: String,
      default: ''
    },
    variableValue: String,
    attributeDescription: String,
    typeDescription: String,
    sectionId: String,
    variableType: String,
    variableName: String,
    enableReminderSetter: {type: Boolean, default: true},
    disabled: Boolean
  },
  data() {
    return {
      config: {
        wrap: true, // set wrap to true only when using 'input-group'
        dateFormat: 'YYYY-MM-DD',
        parseDate: (datestr, format) => {
          return this.$moment(datestr, format, true).toDate();
        },
        formatDate: (date, format, locale) => {
          // locale can also be used
          return this.$moment(date).format(format);
        }
      },
      format: "YYYY-MM-DD",
      type: "DATE",
      value: null,
      language: 'tr',

      executionPlanOptions: [
        {
          displayText: i18n.t('variable.reminderOptions.everyHour'),
          value: 'EVERY_HOUR'
        },
        {
          displayText: i18n.t('variable.reminderOptions.everyDay'),
          value: 'EVERY_DAY'
        },
        {
          displayText: i18n.t('variable.reminderOptions.every3Day'),
          value: 'EVERY_3_DAY'
        },
        {
          displayText: i18n.t('variable.reminderOptions.everyWeek'),
          value: 'EVERY_WEEK'
        },
        {
          displayText: i18n.t('variable.reminderOptions.every2Week'),
          value: 'EVERY_2_WEEK'
        }
      ],

      reminderStartDatePickerConfig: {
        wrap: true, // set wrap to true only when using 'input-group'
        dateFormat: 'YYYY-MM-DD',
        parseDate: (datestr, format) => {
          return this.$moment(datestr, format, true).toDate();
        },
        formatDate: (date, format, locale) => {
          // locale can also be used
          return this.$moment(date).format(format);
        },
        minDate: "today"
      },

      reminderRequest: {
        title: null,
        note: null,
        startDate: null,
        executionPlan: {
          displayText: i18n.t('variable.reminderOptions.everyDay'),
          value: 'EVERY_DAY'
        },
        groupsToRemind: [],
        documentVariableId: this.documentVariableId
      },

      selectedGroups: [],
      selectedUsers: [],
      loading: false
    }
  },
  watch: {
    variableValue: {
      immediate: true,
      handler(val, oldVal) {
      }
    }
  },
  created() {
    this.value = Date.parse(this.variableValue);
    this.loadGroups()
  },
  computed: {
    ...mapState({
      document: state => state.document.document
    }),
    groups() {
      return this.$store.getters['workspaces/groups']
    },
  },
  methods: {
    save() {
      this.$emit("save", {
        varId: this.variableId,
        name: this.variableName,
        inputValue: this.$moment(this.value).format(this.format),
        sectionId: this.sectionId
      })
    },
    addReminder() {
      this.reminderRequest.groupsToRemind = this.selectedGroups.map(u => u.id)
      this.reminderRequest.executionPlan = this.reminderRequest.executionPlan.value

      const payload = {
        documentId: this.document.documentId,
        request: this.reminderRequest
      }

      this.$store.dispatch('reminder/create', payload)
          .then(() => this.$refs['create-reminder-modal'].hide())
          .then(() => {
            this.reminderRequest = {
              title: null,
              note: null,
              startDate: null,
              executionPlan: {
                displayText: i18n.t('variable.reminderOptions.everyDay'),
                value: 'EVERY_DAY'
              },
              groupsToRemind: [],
              documentVariableId: this.documentVariableId
            }
          })
          .then(() => this.fetchDocumentReminders())
          .then(() => this.fetchDocumentBadges())
    },
    fetchDocumentReminders() {
      this.$store.dispatch('document/getDocumentReminders', this.documentIdFromUrl());
    },
    fetchDocumentBadges() {
      this.$store.dispatch('document/fetchDocumentStatistics', this.documentIdFromUrl())
    },
    documentIdFromUrl() {
      return this.$route.params.id;
    },
    openAddReminderModal() {
      this.$refs['create-reminder-modal'].show()
    },
    handleSelection(value) {
      value.users.forEach(addedUser => {
        if (!this.selectedUsers.some(su => su.id === addedUser.id)) {
          this.selectedUsers.push({
            id: addedUser.id,
            fullName: addedUser.fullName,
            selected: true
          })
        }
      })
    },
    handleDeselection(value) {
      value.users.forEach(user => {
        const filterElement = this.selectedUsers.filter(su => su.id === user.id)[0];
        const removedIndex = this.selectedUsers.indexOf(filterElement)
        this.selectedUsers.splice(removedIndex, 1)
      })
    },
    loadGroups() {
      this.loading = true

      this.$store.dispatch('workspaces/fetchGroups', this.document.idWorkspace)
          .then(() => {
            this.loading = false
          })
    },
    validForAddReminder() {
      return (this.reminderRequest.title !== null && this.reminderRequest.title !== '')
          && (this.reminderRequest.note !== null && this.reminderRequest.note !== '')
          && (this.reminderRequest.startDate !== null)
    },
    sendTestMail() {

      const payload = {
        title: this.reminderRequest.title,
        note: this.reminderRequest.note,
        startDate: this.reminderRequest.startDate,
        usersToRemind: this.selectedUsers.map(u => u.id),
        documentVariableId: this.documentVariableId
      }

      this.$store.dispatch('reminder/sendTestMail', payload)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.date-attribute-item {
  width: 100%;
  border: none !important;
  height: 100%;
  border-radius: 3px;
}

.date-attribute-item:hover {
  background-color: #EBECF0;
  border: 1px solid #0f163a;
}
</style>
