<template>
  <div>
    <b-modal no-close-on-backdrop
             ref="select-workspace-modal"
             lazy
             hide-footer
             @hidden="hideWorkspaceSelect"
             :title="$t('document.chooseWorkspace')">
      <validation-observer ref="onboardingDocumentRef"
                           #default="{invalid}">
        <b-form @submit.prevent>
          <b-row align-h="start">
            <b-col class="mb-1">
              <h5>{{ description }}</h5>
            </b-col>
          </b-row>
          <b-row align-h="start" class="w-100">
            <b-col>
              <v-select v-model="selectedWorkspace"
                        :options="workspaces"
                        @input="onSelectedChanged"
                        label="name"
                        class="invoice-filter-select"
                        :placeholder="$t('select.workspaces')">
                <template #selected-option="{ name }">
                      <span class="text-truncate overflow-hidden">
                        {{ name }}
                      </span>
                </template>
              </v-select>
            </b-col>

          </b-row>
          <b-row align-h="start" class="mt-2 w-100">
            <b-col>
              <b-form-checkbox
                  v-if="selectedWorkspace !== null"
                  v-model="isDefault">
                <h5>{{ $t('document.everytime') }}<strong>{{ selectedWorkspace.name }}</strong>{{ $t('document.shouldChosen') }}</h5>
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row align-h="start" class="mt-2 w-100">
            <b-col>
              <b-form-group
                  :label="$t('document.nameYourDocument')"
                  label-for="inputDocName">
                <validation-provider
                    #default="{ errors }"
                    name="inputDocName"
                    vid="inputDocName">
                  <b-form-input
                      id="inputDocName"
                      :placeholder="$t('document.name')"
                      v-model="defaultDocumentName"
                      class="w-full">
                  </b-form-input>
                  <small v-if="errors.length > 0" class="text-danger">
                    {{ $t('required') }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row align-h="start" class="mt-2 w-100" v-if="source === 'DEFAULT'">
            <b-col>
              <b-form-group
                  :label="$t('document.leftSideBar.category')"
                  label-for="documentType">
                <v-select
                    v-model="selectedDocumentType"
                    :options="documentTypes"
                    :label="'displayText'"
                    :placeholder="$t('document.defineDocumentCategory')">
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row align-h="start" class="mt-2 w-100" v-if="!willUpdatedOnChange">
            <b-col cols="12" class="justify-content-end align-content-end d-flex  w-100">
              <b-button
                  @click="createNewDocument"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  :disabled="selectedWorkspace === null || invalid || submitting">
                <b-spinner small v-if="submitting"/>
                <span v-else>{{ $t('continue') }}</span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {BButton, BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BModal, BRow, BSpinner,} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapState} from "vuex";
import vSelect from 'vue-select'
import "vue-select/dist/vue-select.css";
import i18n from "@/libs/i18n";

export default {
  name: "WorkspaceSelectionModal",
  components: {
    BRow,
    BCol,
    BButton,
    BSpinner,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BForm,
    BModal,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    workspaces: Array,
    submitting: Boolean,
    description: String,
    updateOperation: Boolean,
    willUpdatedOnChange: Boolean,
    template: Object,
    source: {
      type: String,
      default: () => {
        return "DEFAULT"
      }
    }
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      populateTemplateVariables: true,
      isDefault: false,
      defaultDocumentName: '',
      selectedDocumentType: null,
      selectedWorkspace: null
    }
  },
  computed: {
    ...mapState({
      // TODO this is a redundant way to fix i18n
      documentTypes: state => state.document.documentTypes.map(dt => {
        return {
          name: dt.name,
          displayText: i18n.t('documentTypes.'.concat(dt.name))
        }
      }),
    }),
    invalid() {
      return this.selectedWorkspace == null
    },
    selectWorkspace() {
      let defaultWorkspaceId = localStorage.getItem('defaultWorkspaceId')
      if (defaultWorkspaceId !== null) {
        return this.workspaces.find(value => value.organizationId === defaultWorkspaceId)
      }
      return null;
    },
    getNewDocumentUrl() {
      // return `/documents/${uuid.v4()}/editor`;
      return `/documents/new/editor`;
    }
  },
  mounted() {
    console.log('mounted workspace')

    this.showModal()
    this.defaultDocumentName = i18n.t('document.namelessDocument')

    if (this.template !== null && this.template !== undefined) {
      this.defaultDocumentName = this.template.title
    }
  },
  beforeDestroy() {
    console.log('before destroy workspace')
    this.$refs['select-workspace-modal'].hide()
  },
  methods: {
    showModal() {
      this.$refs['select-workspace-modal'].show()
    },
    cleanStates() {
      this.$store.dispatch('document/cleanVariables')
      this.$store.dispatch('document/refreshDocument')
    },

    createNewDocument() {
      if (this.template != null) {
        this.$emit('createFromTemplate',
            {
              workspace: this.selectedWorkspace,
              templateId: this.template.id,
              documentTitle: this.defaultDocumentName
            })
      } else {
        this.$router.replace(this.getNewDocumentUrl
            .concat('?ws=')
            .concat(this.selectedWorkspace.organizationId)
            .concat("&title=")
            .concat(this.defaultDocumentName)
            .concat("&type=")
            .concat(this.selectedDocumentType.name))
      }
      this.cleanStates()
    },
    hideWorkspaceSelect() {
      this.$emit('close')
    },
    selectAndContinue() {
      this.setDefault()
      if (this.updateOperation) {
        this.$emit('updateWorkspace', this.selectedWorkspace)
      } else if (this.template != null) {
        this.$emit('createFromTemplate',
            {
              workspace: this.selectedWorkspace,
              templateId: this.template.id,
              documentTitle: this.defaultDocumentName
            })
      }
    },
    setDefault() {
      if (this.isDefault) {
        localStorage.setItem('defaultWorkspaceId', this.selectedWorkspace.organizationId)
      }
    },
    onSelectedChanged() {
      if (this.willUpdatedOnChange) {
        this.selectAndContinue()
      }
    }
  },
}
</script>

<style scoped>

</style>
