<template>
  <div>
    <div class="misc-wrapper">
      <div class="misc-inner p-2 p-sm-3">
        <div class="w-100 text-center">
          <b-img class="w-25 mb-3"
                 fluid
                 :src="imgUrl"
                 alt="Not authorized page"
          />
          <h2 class="mb-2">
            {{ $t('noTemplates') }} ! &#128209;
          </h2>
          <p class="mb-2">
            {{ $t('noTemplatesDescription') }}
          </p>
<!--          <b-button-->
<!--              v-ripple.400="'rgba(113, 102, 240, 0.15)'"-->
<!--              class="mb-1 btn-sm-block"-->
<!--              variant="outline-primary"-->
<!--          >-->
<!--            {{ $t('buttons.create') }}-->
<!--          </b-button>-->
        </div>
      </div>
    </div>
    <!--    <CreateModal ref="cm"></CreateModal>-->
  </div>
</template>

<script>
import {BButton, BImg} from "bootstrap-vue";
import Ripple from 'vue-ripple-directive'

export default {
  name: "Empty",
  components: {
    BImg,
    // BButton,
    // CreateModal
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      modalShow: false,
      emptyImg: require('@/assets/images/illustration/empty.svg'),
    }
  },
  computed: {
    imgUrl() {
      return this.emptyImg
    },
  },
  methods: {
    showModal() {
      this.$refs['cm'].$refs['modal'].show();
    }
  }
}
</script>

<style scoped>

</style>