<template>
  <div class="flex flex-wrap w-full mt-50">
    <b-form-group
        :label="typeDescription"
        :label-for="variableId">
      <validation-provider
          #default="{ errors }"
          :name="variableName"
          :vid="variableName"
          rules="required">
        <v-select :disabled="disabled"
                  single
                  :options="options"
                  v-model="value"
                  @input="handleSelectedOption"
                  :placeholder="$t('choose')"
                  :label="'option'">
        </v-select>
        <small v-if="errors.length > 0" class="text-danger">
          {{ $t('required') }}
        </small>
      </validation-provider>
    </b-form-group>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import {ValidationProvider} from "vee-validate";
import {BFormGroup} from "bootstrap-vue";

export default {
  name: "SelectableVariable",
  components: {vSelect, BFormGroup, ValidationProvider,},
  props: {
    variableId: String,
    sectionId: String,
    variableName: String,
    attributeDescription: String,
    typeDescription: String,
    attributeType: String,
    variableValue: {
      type: String
    },
    options: Array,
    disabled: Boolean
  },
  data() {
    return {
      type: this.attributeType,
      value: this.variableValue,
    }
  },
  methods: {
    handleSelectedOption() {
      this.$emit('save', {varId: this.variableId, selectedValue: this.value, sectionId: this.sectionId})
    }
  }
}
</script>

<style scoped>

</style>