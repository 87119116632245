<template>
  <div class="flex flex-wrap w-full mt-50">
    <b-form-group
        :label="typeDescription"
        :label-for="variableId">
      <validation-provider
          #default="{ errors }"
          :name="variableName"
          :vid="variableName"
          rules="required">
        <v-select :disabled="disabled"
                  single
                  v-model="value"
                  @input="handleSelectedCompany"
                  :placeholder="$t('variable.chooseParticipants')"
                  label="text"
                  :options="companies">
        </v-select>
        <small v-if="errors.length > 0" class="text-danger">
          {{ $t('required') }}
        </small>
      </validation-provider>
    </b-form-group>
  </div>
</template>
<script>

import vSelect from 'vue-select'
import {ValidationProvider} from 'vee-validate'
import {BFormGroup} from "bootstrap-vue";

export default {
  name: "CompanyTypeAttribute",
  components: {vSelect, BFormGroup, ValidationProvider},
  props: {
    variableName: String,
    placeholder: String,
    variableId: String,
    sectionId: String,
    attributeType: String,
    variableValue: Object,
    typeDescription: String,
    workspaceId: String,
    disabled: Boolean
  },
  data() {
    return {
      type: "ORG",
      value: this.variableValue,
    }
  },

  created() {
    this.fetchCompanies()
  },
  mounted() {
  },
  computed: {
    companies() {
      return this.$store.getters['company/listCompaniesForSelection']
    }
  },

  methods: {
    handleSelectedCompany() {
      this.$emit('save', {varId: this.variableId, selectedValues: this.value, sectionId: this.sectionId})
    },
    onValidate() {
      console.log("Hello from %o", this.attributeName)
    },
    async fetchCompanies() {
      await this.$store.dispatch("company/list", {workspaceId: this.workspaceId})
          .then(res => {

          });
    },
  }
}
</script>
