<template>
  <div>
    <loading v-if="loading"></loading>
    <div v-else>
      <Empty v-if="totalTemplates === 0"/>
      <div v-else class="table-resize">
<!--        <b-modal-->
<!--            scrollable-->
<!--            ref="create-template-modal"-->
<!--            hide-footer-->
<!--            no-close-on-backdrop-->
<!--            :title="$t('template.add')">-->
<!--        </b-modal>-->
<!--        <b-modal-->
<!--            scrollable-->
<!--            ref="edit-template-modal"-->
<!--            hide-footer-->
<!--            no-close-on-backdrop-->
<!--            :title="$t('template.edit')">-->
<!--        </b-modal>-->
        <b-modal no-close-on-backdrop
                 ref="select-workspace-modal"
                 lazy
                 hide-footer
                 @hidden="hideWorkspaceSelect"
                 title="Bir çalışma alanı seç">
          <select-workspace @createFromTemplate="createFromTemplate"
                            @hide="hideWorkspaceSelect"
                            @close="modalClosed"
                            :source="'TEMPLATE'"
                            :template="selectedTemplate"
                            :submitting="submitting"
                            :description="$t('document.documentRequiresWorkspace')"
                            :workspaces="workspaces"/>
        </b-modal>
        <populate-variables-modal/>
        <div class="my-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
<!--              <b-button-->
<!--                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"-->
<!--                  variant="warning"-->
<!--                  class="rounded-circle mr-1"-->
<!--                  @click="addTemplate">-->
<!--                <div class="icon-wrapper">-->
<!--                  <feather-icon icon="FileIcon" size="21"/>-->
<!--                </div>-->
<!--              </b-button>-->
              <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mr-1"/>
            </b-col>
            <!-- Search -->
            <b-col
                cols="6"
                md="6">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                    v-model="searchQuery"
                    class="d-inline-block mr-1"
                    :placeholder="$t('search')"
                />
              </div>
            </b-col>
          </b-row>
        </div>
        <b-table
            ref="refTemplateListTable"
            :items="templates"
            responsive
            :small="false"
            :fields="tableColumns"
            primary-key="id"
            :hover="true"
            :head-variant="null"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc"
            class="position-relative templates-table">

          <template #head(name)>
            <span>{{ $t('table.title') }}</span>
          </template>

          <template #head(templateDocumentType)>
            <span>{{ $t('table.documentType') }}</span>
          </template>

          <template #head(createdAt)>
            <span>{{ $t('table.createdAt') }}</span>
          </template>

          <template #head(public)>
            <span>{{ $t('table.public') }}</span>
          </template>

          <template #head(actions)>
            <span>{{ $t('table.actions') }}</span>
          </template>

          <template #cell(name)="data">
            <b-link @click="editTemplate(data.item)"
                    class="font-weight-bold">
              {{ data.item.title }}
            </b-link>
          </template>

          <template #cell(templateDocumentType)="data">
            <b-badge pill
                     class="text-capitalize">
              {{
                $t('documentTypes.'.concat(data.item.templateDocumentType.name))
              }}
            </b-badge>
          </template>

          <template #cell(createdAt)="data">
            <b-link class="font-weight-bold">
              {{ getFormattedDate(data.item.createdAt) }}
            </b-link>
          </template>

          <template #cell(public)="data">
            <b-link class="font-weight-bold">
              {{ data.item.public ? $t('yes') : $t('no') }}
            </b-link>
          </template>

          <template #cell(actions)="data">
            <b-button
                v-if="authenticated(data.item)"
                @click="deleteTemplate(data.item)"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="danger"
                class="rounded-circle mr-1"
                v-b-tooltip.hover.top="$t('buttons.delete')">
              <feather-icon
                  icon="TrashIcon"
                  size="15"
              />
            </b-button>
            <b-button
                @click="useThisTemplate(data.item)"
                class="rounded-circle"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="success"
                v-b-tooltip.hover.top="$t('buttons.useAsTemplate')">
              <feather-icon
                  icon="FileTextIcon"
                  size="15"
              />
            </b-button>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
            <span class="text-muted">{{
                $tc('itemCountOnTable', dataMeta.from, dataMeta.to, {
                  from: dataMeta.from,
                  to: dataMeta.to
                })
              }}</span>
            </b-col>
            <!-- Pagination -->
            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalTemplates"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import {BBadge, BButton, BCol, BFormInput, BLink, BModal, BPagination, BRow, BTable, VBTooltip} from "bootstrap-vue";
import vSelect from "vue-select";
import {ref} from "@vue/composition-api";
import {avatarText} from "@core/utils/filter";
import useTemplatesList from "@/views/Templates/List/useTemplatesList";
import Loading from '../../components/Loading/Loading'
import {mapState} from "vuex";
import {PEventBus} from "@/services/PEventBus";
import Empty from "../List/Empty"
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import i18n from "@/libs/i18n";
import DateUtil from "@/services/DateUtil";
import SelectWorkspace from "@/views/Documents/Create/CreateBlankDocumentModal";
import PopulateVariablesModal from "@/views/Documents/Create/PopulateVariablesModal";

export default {
  name: "TemplateList",
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    Loading,
    BCol,
    BFormInput,
    BTable,
    BButton,
    BLink,
    BBadge,
    BPagination,
    // BModal,
    Empty,
    // CreateTemplate,
    // EditTemplate,
    vSelect,
    SelectWorkspace,
    PopulateVariablesModal
  },
  computed: {
    ...mapState({
      loading: state => state.template.loading,
      templates: state => state.template.templates,
      workspaces: state => state.workspaces.myWorkspaces
    }),
  },
  data() {
    return {
      selectedTemplate: null,
      submitting: false,
    }
  },
  methods: {
    fetchMyWorkspaces() {
      this.$store.dispatch('workspaces/fetchOrganizations')
    },
    addTemplate() {
      this.$refs['create-template-modal'].show()
    },
    editTemplate(template) {
      this.selectedTemplate = template
      this.$refs['edit-template-modal'].show()
    },
    updateVisible() {
      this.$refs['create-template-modal'].hide()
    },
    deleteTemplate(selectedTemplate) {
      this.$bvModal
          .msgBoxConfirm(this.$i18n.t('messages.template.questionBeforeDelete'), {
            title: i18n.t('messages.areYouSure'),
            size: 'sm',
            okVariant: 'danger',
            okTitle: i18n.t('yes'),
            cancelTitle: i18n.t('exit'),
            cancelVariant: 'outline-primary',
            hideHeaderClose: false,
            centered: false,
          })
          .then(value => {
            if (value) {
              let icon = 'TrashIcon'
              this.$store.dispatch('template/delete', selectedTemplate.id)
                  .then(() => {
                    this.$toast({
                          component: ToastificationContent,
                          props: {
                            title: i18n.t('messages.notification'),
                            icon,
                            text: i18n.t('messages.template.deleted'),
                            variant: 'danger',
                          },
                        })
                  })
                  .then(() => this.refetchData())
            }
          })
    },
    authenticated(selectedTemplate) {
      const userData = JSON.parse(localStorage.getItem('userData'));
      return userData.userId === selectedTemplate.ownerId
    },
    getFormattedDate(date) {
      return DateUtil.getFormattedTime(date);
    },
    hideWorkspaceSelect() {
      this.selectedTemplate = null
      this.$refs['select-workspace-modal'].hide()
    },
    createFromTemplate(event) {
      this.submitting = true
      this.$store.dispatch('document/createFromTemplate', {
        templateId: event.templateId,
        workspaceId: event.workspace.organizationId,
        documentTitle: event.documentTitle
      })
          .then((uid) => {
            console.log("after document/createFromTemplate")
            console.log(uid)
            console.log("after document/createFromTemplate")
            if (uid === 9010) {
              console.log("in if")
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: i18n.t('messages.document.requiresGroupRelation'),
                  icon: 'AlertTriangeIcon',
                  variant: 'danger'
                },
              }, {position: 'top-center'})

              this.$router.push({name: 'organizations'})
              this.hideWorkspaceSelect()
            } else {
              console.log("in else")
              this.submitting = false
              this.$router.replace(`/documents/${uid}/editor`)
              this.selectedTemplate = null
            }
          })
          .catch((err) => this.submitting = false)

    },
    modalClosed() {
      this.selectedTemplateId = null
    },
    useThisTemplate(item) {
      this.$refs['select-workspace-modal'].show()
      this.selectedTemplate = item
    }
  },
  mounted() {
    PEventBus.$on('TEMPLATE_CREATED', this.updateVisible)
    this.fetchMyWorkspaces()
    this.fetchTemplates()
  },
  setup() {

    const isAddNewUserSidebarActive = ref(false)

    const {
      fetchTemplates,
      tableColumns,
      perPage,
      currentPage,
      totalTemplates,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTemplateListTable,
      refetchData,
      resolveDocumentTypeVariant
    } = useTemplatesList()



    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchTemplates,
      tableColumns,
      perPage,
      currentPage,
      totalTemplates,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTemplateListTable,
      refetchData,
      resolveDocumentTypeVariant,

      // Filter
      avatarText,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.per-page-selector {
  width: 90px;
}


.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.table-resize {
	@media screen and (max-width: 900px) {
		overflow: auto;
	}
}

.templates-table {
	tbody {
		tr {
			td {
				a, span {
					font-size: 15px;
					@media screen and (max-width: 1550px) {
						font-size: 13px;
					}
				}
				&:first-child {
					@media screen and (max-width: 1441px) {
						max-width: 300px;
					}
					@media screen and (max-width: 1367px) {
						max-width: 270px;
					}
				}
			}
		}
	}
	@media screen and (max-width: 900px) {
		min-width: 1000px;
	}
}

</style>

<style lang="scss">
</style>
